import React from 'react';
import {RegisterForm} from "../../components/session";
import {Link} from 'gatsby';
import {navigateTo} from "gatsby-link";
import * as Paths from '../../constants/Paths';
import LayoutA from "../../layouts/LayoutA";
import {Icon, Button} from '@blueprintjs/core'
import {getGoogleAuthRedirect} from "../../api/SessionApi";

class RegisterPage extends React.Component {

  render() {
    return (
      <LayoutA>
        <div className="LoginTemplate">
          <div className="LoginTemplate-floatingform">
            <h1 className="LoginTemplate-title">Register New Account</h1>
            <div className="LoginTemplate-oauthbuttons">
              <Button type="submit"
                      className="s2"
                      intent="primary"
                      text="Sign Up With Google."
                      onClick={() => getGoogleAuthRedirect().then((result) => window.location = result.url )}
                      large={true}
              />
            </div>
            <div className="LoginTemplate-separator">or</div>
            <RegisterForm onSuccess={() => navigateTo(Paths.DB_USER_HOME)}/>
            <div className="LoginTemplate-buttonbar">
              <Link to={"/account/login"} className="LoginTemplate-buttonbar">
                <Icon icon="arrow-left" />
                <span className="pt-icon-standard pt-icon-arrow-left"/>Back to Login
              </Link>
            </div>
          </div>
        </div>
      </LayoutA>
    );
  }
}

export default RegisterPage;